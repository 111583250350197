/**
 * We need to do the following to *our* objects before passing to freestyle:
 * - For any `$nest` directive move up to FreeStyle style nesting
 * - For any `$unique` directive map to FreeStyle Unique
 * - For any `$debugName` directive return the debug name
 */
export function convertToStyles(object) {
    /** The final result we will return */
    var styles = {};
    for (var key in object) {
        /** Grab the value upfront */
        var val = object[key];
        /** TypeStyle configuration options */
        if (key === '$nest') {
            var nested = val;
            for (var selector in nested) {
                var subproperties = nested[selector];
                styles[selector] = convertToStyles(subproperties);
            }
        }
        else if (key === '$debugName') {
            styles.$displayName = val;
        }
        else {
            styles[key] = val;
        }
    }
    return styles;
}
// todo: better name here
export function convertToKeyframes(frames) {
    var result = {};
    for (var offset in frames) {
        if (offset !== '$debugName') {
            result[offset] = frames[offset];
        }
    }
    if (frames.$debugName) {
        result.$displayName = frames.$debugName;
    }
    return result;
}
